export const getNotificationTitle = (notification: EnumNotificationType) => {
  switch (notification) {
    case "issue":
      return "New Issue"
    case "purchase":
      return "New Purchase"
    case "feedback":
      return "New Feedback"
    case "reply":
      return "New Reply"
    default:
      return
  }
}
