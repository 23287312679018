import { FC } from "react"

import { Button, Col, Row } from "antd"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"

import { useApp } from "src/app"
import { Logo, SvgIcon } from "src/shared/ui"

import "./Sider.less"

const additionalSiderNav = [
  { name: "Shop settings", path: "/settings", icon: "settings" },
  { name: "Help center", path: "/help", icon: "help" },
]

const Sider: FC<{ setOpen?: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setOpen }) => {
  const { pathname } = useLocation()
  const { feedback } = useApp()

  const feedbackPath = feedback.lastIssue ? `/feedback/${feedback.lastIssue}` : "/feedback"

  const siderNav = [
    { name: "Dashboard", path: "/dashboard", icon: "dashboard" },
    { name: "Products", path: "/products", icon: "products" },
    { name: "Orders", path: "/orders", icon: "orders" },
    { name: "Reports", path: "/reports", icon: "reports" },
    { name: "Coupons", path: "/coupons", icon: "coupons" },
    { name: "Issues", path: "/issues", icon: "queries" },
    { name: "Feedback", path: feedbackPath, icon: "feedback" },
  ]

  const isThisPage = (path: string) => {
    return path.split("/")[1] === pathname.split("/")[1]
  }

  return (
    <Row className={"layout-sider"} gutter={[0, 40]}>
      <Col span={24}>
        <Row gutter={[0, 40]}>
          <Col span={24}>
            <Logo className={"logo"} />
          </Col>
          <Col span={24}>
            <Row gutter={[0, 8]}>
              {siderNav.map(it => (
                <Col key={it.path} span={24}>
                  <NavLink to={it.path} onClick={() => setOpen && setOpen(false)}>
                    <Button
                      block
                      size={"small"}
                      className={"sider-btn"}
                      icon={<SvgIcon type={it.icon} />}
                      type={isThisPage(it.path) ? "primary" : "text"}
                    >
                      {it.name}
                    </Button>
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24} className={"addictional-buttons"}>
        <Row gutter={[0, 8]}>
          {additionalSiderNav.map(it => (
            <Col key={it.path} span={24}>
              <NavLink to={it.path} onClick={() => setOpen && setOpen(false)}>
                <Button
                  block
                  size={"small"}
                  className={"sider-btn"}
                  icon={<SvgIcon type={it.icon} />}
                  type={isThisPage(it.path) ? "primary" : "text"}
                >
                  {it.name}
                </Button>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default Sider
