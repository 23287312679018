import { ThemeConfig } from "antd/es/config-provider"
import { COLORS } from "./variables"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Spin: {
      colorPrimary: COLORS.YELLOW,
      colorText: COLORS.BLACK,
    },
    InputNumber: {
      colorPrimaryBorderHover: COLORS.YELLOW,
    },
    Button: {
      colorPrimaryHover: COLORS.YELLOW,
      colorPrimaryActive: COLORS.ACTIVE.YELLOW,
      colorLinkHover: COLORS.YELLOW,
      colorLinkActive: COLORS.ACTIVE.YELLOW,
    },
    Table: {
      colorBgContainer: COLORS.WHITE,
      colorFillAlter: COLORS.BEIGE,
      colorBorderSecondary: COLORS.LIGHT_GRAY,
      colorTextHeading: COLORS.BLACK,
      colorText: COLORS.DARK_GRAY,
      borderRadiusLG: 16,
      fontSize: 16,
      lineHeight: 1.5,
      padding: 8,
      paddingContentVerticalLG: 12,
      fontWeightStrong: 400,
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
  },
}

export default theme
