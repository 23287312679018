import { FC } from "react"
import { ReactComponent as Default } from "./assets/Logo.svg"
import { ReactComponent as Primary } from "./assets/YellowLogo.svg"

import { NavLink } from "react-router-dom"
import "./index.less"

const logos = {
  default: <Default />,
  primary: <Primary />,
}

export const Logo: FC<{ className?: string; onClick?: VoidFunction; type?: "default" | "primary" }> = ({
  className,
  onClick,
  type = "default",
}) => {
  return (
    <div onClick={onClick} className={className}>
      <NavLink to={"/"}>{logos[type]}</NavLink>
    </div>
  )
}
