import { initMarker } from "@bn-digital/sdk"
import "src/app/styles/common.less"

import { createRoot } from "react-dom/client"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import App from "./app"

const rootElement = document.querySelector("#root") as HTMLElement

initMarker({ enabled: true, destination: import.meta.env.VITE_WEBSITE_MARKER_ID })

createRoot(rootElement).render(
  <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}>
    <App />
  </GoogleReCaptchaProvider>
)
