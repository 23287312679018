import { FC } from "react"
import "./index.less"

import { Typography } from "antd"
import { NavLink } from "react-router-dom"

const { Text } = Typography

export const Navigation: FC<{ direction?: "vertical" | "horizontal"; items: ComponentUiLink[] }> = ({
  direction = "horizontal",
  items,
}) => {
  return (
    <div className={`navigation ${direction}`}>
      {items?.map(link => (
        <NavLink key={link?.title} to={link?.url ?? "/"} className={"link"}>
          <Text type={"secondary"}>{link?.title}</Text>
        </NavLink>
      ))}
    </div>
  )
}
