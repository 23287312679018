export const getSearchDataType = (type: string): string => {
  switch (type) {
    case "issue":
      return "Issues"
    case "product":
      return "Products"
    default:
      return ""
  }
}
