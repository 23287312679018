import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"

import { Loader } from "src/shared/ui"
import { DefaultLayout, LandingLayout } from "src/widgets"
import NotFound from "./not-found"

const Landing = lazy<FC>(() => import("./landing"))
const WaitingList = lazy<FC>(() => import("./waiting-list"))
const ContactSeller = lazy<FC>(() => import("./contact"))
const LoginLayout = lazy<FC>(() => import("./login"))
const Login = lazy<FC>(() => import("./login/Login"))
const Signup = lazy<FC>(() => import("./login/Signup"))
const ForgotPassword = lazy<FC>(() => import("./login/ForgotPassword"))
const Dashboard = lazy<FC>(() => import("./dashboard"))
const ProductsLayout = lazy<FC>(() => import("./products/ui/ProductsLayout"))
const DefaultProducts = lazy<FC>(() => import("./products/ui/DefaultProducts"))
const CreateProduct = lazy<FC>(() => import("./products/ui/CreateProduct"))
const EditProduct = lazy<FC>(() => import("./products/ui/EditProduct"))
const Orders = lazy<FC>(() => import("./orders"))
const Reports = lazy<FC>(() => import("./reports/ui/Reports"))
const Coupons = lazy<FC>(() => import("./coupons"))
const EditProfile = lazy<FC>(() => import("./edit-profile"))
const ShopSettings = lazy<FC>(() => import("./shop-settings"))
const PaymentVerification = lazy<FC>(() => import("./payment-verification"))
const Issues = lazy<FC>(() => import("./issues"))
const Feedback = lazy<FC>(() => import("./feedback"))
const Store = lazy<FC>(() => import("./store"))
const Product = lazy<FC>(() => import("./product"))

const routes: RouteObject[] = [
  {
    Component: LoginLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Login,
        path: "/login",
      },
      {
        Component: Signup,
        path: "/signup",
      },

      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
    ],
  },
  {
    Component: LandingLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Landing,
        path: "/",
        index: true,
      },
      {
        Component: WaitingList,
        path: "/waiting-list",
      },
      {
        Component: ContactSeller,
        path: "/contact",
      },
      {
        Component: Store,
        path: "/:store/products",
      },
      {
        Component: Product,
        path: "/:store/product/:product",
      },
      {
        Component: NotFound,
        path: "*",
      },
    ],
  },
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        Component: ProductsLayout,
        path: "/products",
        children: [
          {
            Component: DefaultProducts,
            index: true,
          },
          {
            Component: CreateProduct,
            path: "create",
          },
          {
            Component: EditProduct,
            path: "edit/:id",
          },
        ],
      },
      {
        Component: Orders,
        path: "/orders",
      },
      {
        Component: PaymentVerification,
        path: "/payment-verification/:sessionId",
      },
      {
        Component: Reports,
        path: "/reports",
      },
      {
        Component: Coupons,
        path: "/coupons",
      },
      {
        Component: Issues,
        path: "/issues",
      },
      {
        Component: Feedback,
        path: "/feedback",
      },
      {
        Component: Feedback,
        path: "/feedback/:id",
      },
      {
        Component: EditProfile,
        path: "/edit-profile",
      },
      {
        Component: ShopSettings,
        path: "/settings",
      },
      {
        Component: NotFound,
        path: "*",
      },
    ],
  },
]

export default routes
