import { FC, PropsWithChildren } from "react"
import "./index.less"

type ContainerProps = {
  className?: string
}

export const Container: FC<PropsWithChildren<ContainerProps>> = ({ children, className }) => {
  return (
    <div className={`container ${className}`}>
      <div className={"container-child"}>{children}</div>
    </div>
  )
}
