export const COLORS = {
  BEIGE: "#F4F1ED",
  BLACK: "#1C0E0C",
  DARK_GRAY: "#6A6A6A",
  LIGHT_GRAY: "#E6E6E6",
  WHITE: "#FFFFFF",
  YELLOW: "#EFAF00",
  ORANGE: "#F46F40",
  RED: "#890000",

  ACTIVE: {
    YELLOW: "#E1A500",
  },
}
