import { Badge, Button, Dropdown, Typography } from "antd"
import dayjs from "dayjs"
import { FC } from "react"
import { Link, useLocation } from "react-router-dom"
import { useNotificationsQuery, useUpdateNotificationMutation } from "src/graphql"
import { getNotificationTitle } from "src/shared/helpers"
import { useBreakpoints } from "src/shared/hooks"
import { SvgIcon } from "src/shared/ui"
import "./index.less"

export const Notifications: FC = () => {
  const { isMobile } = useBreakpoints()
  const location = useLocation()

  const { data: notificationsData } = useNotificationsQuery()
  const [updateNotification] = useUpdateNotificationMutation()

  const notifications = notificationsData?.notifications?.data.map(({ id, attributes }) => ({
    id,
    ...attributes,
  }))

  const noNotification = [
    {
      key: "no-notifications",
      style: { cursor: "default" },
      label: (
        <Typography.Text className={"title"} type={"secondary"}>
          Notification list is empty
        </Typography.Text>
      ),
    },
  ]

  const handleClick = async (id: string) => {
    console.log(id)
    await updateNotification({
      variables: {
        id,
        data: {
          isRead: true,
        },
      },
    })
  }

  const notificationItems = notifications?.length
    ? notifications
        .map((n, i) => ({
          key: n.id ?? i,
          label: (
            <Badge dot={!n?.isRead ?? true} color={"#F46F40"} size={"default"}>
              <Link className={"notification-item"} onClick={() => handleClick(n?.id ?? "")} to={n?.link ?? location}>
                <Typography className={"title"}>
                  {getNotificationTitle(n?.type ?? "feedback")}
                  <Typography.Text type={"secondary"}>
                    {dayjs(n?.createdAt ?? new Date()).format("DD.MM.YY")}
                  </Typography.Text>
                </Typography>
                <Typography.Text className={"text"} type={"secondary"}>
                  {n?.message ?? ""}
                </Typography.Text>
              </Link>
            </Badge>
          ),
        }))
        .reverse()
    : noNotification

  return (
    <Dropdown
      rootClassName={"notification-dropdown"}
      trigger={["hover"]}
      menu={{
        items: notificationItems,
      }}
      placement={isMobile ? "bottom" : "bottomLeft"}
    >
      <Badge
        className={"notification"}
        dot={!!notifications?.find(n => n.isRead === false)}
        color={"#F46F40"}
        size={"default"}
      >
        <Button icon={<SvgIcon type={"notification"} />} />
      </Badge>
    </Dropdown>
  )
}

export default Notifications
