import { FC, SVGProps } from "react"
import { ReactComponent as Approve } from "./icons/Approve.svg"
import { ReactComponent as BTC } from "./icons/BTC.svg"
import { ReactComponent as Burger } from "./icons/Burger.svg"
import { ReactComponent as Calendar } from "./icons/Calendar.svg"
import { ReactComponent as Card } from "./icons/Card.svg"
import { ReactComponent as Close } from "./icons/Close.svg"
import { ReactComponent as CopyText } from "./icons/CopyText.svg"
import { ReactComponent as Coupons } from "./icons/Coupons.svg"
import { ReactComponent as Dashboard } from "./icons/Dashboard.svg"
import { ReactComponent as Delete } from "./icons/Delete.svg"
import { ReactComponent as Download } from "./icons/Download.svg"
import { ReactComponent as Edit } from "./icons/Edit.svg"
import { ReactComponent as EditProfile } from "./icons/EditProfile.svg"
import { ReactComponent as Facebook } from "./icons/Facebook.svg"
import { ReactComponent as Feedback } from "./icons/Feedback.svg"
import { ReactComponent as Help } from "./icons/Help.svg"
import { ReactComponent as Image } from "./icons/Image.svg"
import { ReactComponent as Instagram } from "./icons/Instagram.svg"
import { ReactComponent as Link } from "./icons/Link.svg"
import { ReactComponent as LinkedIn } from "./icons/LinkedIn.svg"
import { ReactComponent as Logout } from "./icons/Logout.svg"
import { ReactComponent as Macros } from "./icons/Macros.svg"
import { ReactComponent as Menu } from "./icons/Menu.svg"
import { ReactComponent as Notification } from "./icons/Notification.svg"
import { ReactComponent as Orders } from "./icons/Orders.svg"
import { ReactComponent as Products } from "./icons/Products.svg"
import { ReactComponent as Queries } from "./icons/Queries.svg"
import { ReactComponent as Reports } from "./icons/Reports.svg"
import { ReactComponent as Search } from "./icons/Search.svg"
import { ReactComponent as SearchInput } from "./icons/SearchInput.svg"
import { ReactComponent as Settings } from "./icons/Settings.svg"
import { ReactComponent as Test } from "./icons/Test.svg"
import { ReactComponent as Twitter } from "./icons/Twitter.svg"
import { ReactComponent as ViewReport } from "./icons/ViewReport.svg"

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  calendar: Calendar,
  instagram: Instagram,
  facebook: Facebook,
  twitter: Twitter,
  linkedin: LinkedIn,
  burger: Burger,
  dashboard: Dashboard,
  products: Products,
  orders: Orders,
  reports: Reports,
  coupons: Coupons,
  queries: Queries,
  macros: Macros,
  feedback: Feedback,
  settings: Settings,
  help: Help,
  search: Search,
  notification: Notification,
  editProfile: EditProfile,
  logout: Logout,
  searchInput: SearchInput,
  close: Close,
  image: Image,
  menu: Menu,
  edit: Edit,
  link: Link,
  delete: Delete,
  approve: Approve,
  viewReport: ViewReport,
  download: Download,
  test: Test,
  btc: BTC,
  copyText: CopyText,
  card: Card,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: string }

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}
