import { Typography } from "antd"
import { FC } from "react"
import "./index.less"

type HighlightTextProps = {
  text: string
  highlight: string
}

export const HighlightText: FC<HighlightTextProps> = ({ text, highlight }) => {
  const regex = new RegExp(`(${highlight})`, "gi")

  const parts = text.split(regex)

  return (
    <Typography>
      {parts.map((part, i) =>
        regex.test(part) ? (
          <span key={i} className={"highlight-text"}>
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        )
      )}
    </Typography>
  )
}
