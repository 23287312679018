import { Layout } from "antd"
import { FC, Suspense, useLayoutEffect } from "react"
import { Outlet, useLocation } from "react-router"
import { useLayoutQuery, useMeQuery } from "src/graphql"
import { Loader } from "src/shared/ui"
import { Footer } from "./Footer"
import { Header } from "./Header"
import "./Layout.less"

export const LandingLayout: FC = () => {
  const { data, loading } = useLayoutQuery()
  const { data: me, loading: meLoading } = useMeQuery()
  const { pathname } = useLocation()
  const navigationItems = data?.layout?.data?.attributes?.headerMenu as ComponentUiLink[]
  const footerSocialMedia = data?.layout?.data?.attributes?.footerSocialMedia as ComponentUiLink[]
  const footerNav = data?.layout?.data?.attributes?.footerNav as ComponentUiFooterNavGroup[]

  useLayoutEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
    }
  }, [me, pathname])

  if (loading && meLoading) {
    return <Loader spinning />
  }

  return (
    <Layout>
      <Layout.Header className={"landing-header"}>
        <Header items={navigationItems} />
      </Layout.Header>
      <Layout.Content>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout.Content>
      <Layout.Footer>
        <Footer socialMedia={footerSocialMedia} navItems={footerNav} />
      </Layout.Footer>
    </Layout>
  )
}
