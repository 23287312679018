import { Button, Result } from "antd"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

const NotFound: FC = () => {
  const navigate = useNavigate()
  return (
    <Result
      status={"404"}
      title={"404"}
      subTitle={"Sorry, the page you visited does not exist."}
      extra={
        <Button onClick={() => navigate(-1)} type={"primary"}>
          Go back
        </Button>
      }
    />
  )
}

export default NotFound
