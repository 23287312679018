import { Button, Col, Drawer, Row, Space } from "antd"
import { FC, useState } from "react"
import { NavLink } from "react-router-dom"
import { useBreakpoints } from "src/shared/hooks"
import { Container, Logo, SvgIcon } from "src/shared/ui"
import { Navigation } from "src/widgets"
import "./Header.less"

const Header: FC<{ items?: ComponentUiLink[] }> = ({ items = [] }) => {
  const { isMobile } = useBreakpoints()
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <Row align={"middle"} justify={"space-between"}>
        <Col span={isMobile ? 24 : undefined}>
          <Row align={"middle"} justify={"space-between"} gutter={[{ sm: 0, md: 40 }, 0]}>
            <Col>
              <Logo />
            </Col>
            {isMobile ? (
              <Col>
                <Button icon={<SvgIcon type={"burger"} />} onClick={() => setOpen(prev => !prev)} />
              </Col>
            ) : (
              <Col>
                <Navigation items={items} />
              </Col>
            )}
          </Row>
        </Col>
        {!isMobile && (
          <Col>
            <NavLink to={"/login"}>
              <Button type={"text"}>Log In</Button>
            </NavLink>
            <NavLink to={"/signup"}>
              <Button>Sign Up</Button>
            </NavLink>
          </Col>
        )}
      </Row>

      {/* BURGER MENU OPEN */}

      <Drawer
        rootClassName={"landing-header-drawer"}
        placement={"top"}
        height={"auto"}
        open={open && isMobile}
        closable={false}
        maskClosable
      >
        <Container>
          <Row align={"middle"} justify={"center"}>
            <Col span={24} className={"navigation-container"}>
              <Space direction={"vertical"} size={32} style={{ width: "100%" }}>
                <Navigation direction={"vertical"} items={items} />
                <Space direction={"vertical"} size={14} style={{ width: "100%" }}>
                  <NavLink to={"/login"}>
                    <Button block type={"text"}>
                      Log In
                    </Button>
                  </NavLink>
                  <NavLink to={"/signup"}>
                    <Button block>Sign Up</Button>
                  </NavLink>
                </Space>
              </Space>
            </Col>
          </Row>
        </Container>
      </Drawer>
    </Container>
  )
}

export { Header }
