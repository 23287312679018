import { Button, Col, Row, Space, Typography } from "antd"
import { FC } from "react"
import { Link, NavLink } from "react-router-dom"
import { useBreakpoints } from "src/shared/hooks"
import { Container, Logo, SvgIcon } from "src/shared/ui"
import "./Footer.less"

const { Title, Text } = Typography

type FooterProps = {
  socialMedia?: ComponentUiLink[]
  navItems?: ComponentUiFooterNavGroup[]
}

const Footer: FC<FooterProps> = ({ socialMedia = [], navItems = [] }) => {
  const { isMobile } = useBreakpoints()

  return (
    <Container className={"footer"}>
      <Row gutter={[0, isMobile ? 64 : 20]}>
        <Col span={24}>
          <Row justify={"space-between"} gutter={[24, 56]}>
            <Col xs={24} md={24} lg={13} xl={13} className={"footer-left-side"}>
              <Logo type={"primary"} />
              <Text className={"footer-text"}>
                E-commerce is easy with us. Create your own customizable online store today.
              </Text>
              <Space size={10}>
                {socialMedia.map(it => (
                  <Link key={it.title} to={it.url} target={it.target}>
                    <Button type={"primary"} shape={"circle"} icon={<SvgIcon type={it?.title ?? ""} />} />
                  </Link>
                ))}
              </Space>
            </Col>
            <Col xs={24} md={24} lg={11} xl={11} className={"footer-navigation"}>
              <Row justify={"space-between"} gutter={[40, 56]}>
                {navItems.map(it => (
                  <Col key={it.title} span={isMobile ? 24 : 8}>
                    <Row gutter={[0, 30]}>
                      <Col span={24}>
                        <Title>{it.title}</Title>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[0, 12]}>
                          {it.links.map(it => (
                            <Col key={it?.title} span={24}>
                              <NavLink to={it?.url ?? "/"} target={it?.target}>
                                <Text>{it?.title}</Text>
                              </NavLink>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Text type={"secondary"}>© 2023, Vendr LLC</Text>{" "}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export { Footer }
