import { Avatar, Button, Col, Drawer, Dropdown, MenuProps, Row, Space, Typography } from "antd"
import { FC, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSearchLazyQuery } from "src/graphql"
import { useBreakpoints } from "src/shared/hooks"
import { Autocomplete, AutocompleteDataType, Logo, SvgIcon } from "src/shared/ui"
import { Notifications } from "src/widgets"
import "./Header.less"
import Sider from "./Sider"
import avatar from "./assets/avatar.png"

const LogoutButton = () => {
  const navigate = useNavigate()

  const onLogout = () => {
    localStorage.removeItem("jwt")
    navigate("/login")
  }

  return (
    <Button style={{ padding: 0 }} type={"text"} onClick={onLogout}>
      <Typography>Log out</Typography>
    </Button>
  )
}

const profileDropdownItems: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <Link to={"/edit-profile"}>
        <Typography>Edit profile</Typography>
      </Link>
    ),
    icon: <SvgIcon type={"editProfile"} />,
  },
  {
    key: "2",
    label: <LogoutButton />,
    icon: <SvgIcon type={"logout"} />,
  },
]

const Header: FC = () => {
  const { isMobile } = useBreakpoints()
  const [openSider, setOpenSider] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [search] = useSearchLazyQuery()

  const getData = async (value: string) => {
    const data = await search({
      variables: {
        q: value,
      },
    })

    return data.data?.search as AutocompleteDataType[]
  }

  return (
    <Row justify={"space-between"} align={"middle"}>
      <Col span={10}>
        {!isMobile ? (
          <Autocomplete getData={getData} />
        ) : (
          <Space size={12}>
            <Button onClick={() => setOpenSider(true)} icon={<SvgIcon type={"burger"} />} />
            <Logo className={"header-logo"} />
          </Space>
        )}
      </Col>

      <Col>
        <Space size={20}>
          {isMobile && (
            <Button
              onClick={() => setOpenSearch(prev => !prev)}
              icon={<SvgIcon type={openSearch ? "close" : "search"} />}
            />
          )}
          <Notifications />
          <Dropdown trigger={["click"]} menu={{ items: profileDropdownItems }} placement={"bottomLeft"}>
            <Button className={"profile-btn"} ghost>
              <Avatar size={44} src={avatar} icon={!avatar && <SvgIcon type={"help"} />} alt={"Avatar"} />
            </Button>
          </Dropdown>
        </Space>
      </Col>

      <Drawer
        maskClosable
        onClose={() => setOpenSearch(false)}
        rootClassName={"search-drawer"}
        placement={"top"}
        height={"auto"}
        closable={false}
        open={isMobile && openSearch}
      >
        <Autocomplete getData={getData} />
      </Drawer>

      <Drawer
        maskClosable
        onClose={() => setOpenSider(false)}
        rootClassName={"sider-drawer"}
        placement={"left"}
        closable={false}
        open={isMobile && openSider}
      >
        <Sider setOpen={setOpenSider} />
      </Drawer>
    </Row>
  )
}

export default Header
