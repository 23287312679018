import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, useState, type FC, type PropsWithChildren } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import introspection from "src/graphql"
import routes from "src/pages"
import { version } from "../../package.json"
import theme from "./themes"

type ContextProps = {
  app: { version: string }
  feedback: { lastIssue: string | null; setLastIssue: (latIssue: string | null) => void }
  user: {
    email: string | null
    setEmail: (email: string | null) => void
    authenticated: boolean | null
    avatar: string | null
  }
  store: { name: string | null; setName: (email: string | null) => void }
}

const app: ContextProps = {
  app: { version },
  feedback: { lastIssue: null, setLastIssue: () => null },
  user: { email: null, setEmail: () => null, authenticated: null, avatar: null },
  store: { name: null, setName: () => null },
}

const Context = createContext(app)

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("jwt")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => {
  const [lastIssue, setLastIssue] = useState<string | null>(localStorage.getItem("feedbackLastIssue") ?? null)
  app.feedback.lastIssue = lastIssue
  app.feedback.setLastIssue = setLastIssue

  const [email, setEmail] = useState<string | null>(null)
  app.user.email = email
  app.user.setEmail = setEmail

  const [name, setName] = useState<string | null>(null)
  app.store.name = name
  app.store.setName = setName

  return (
    <ApolloProvider client={client}>
      <ContextProvider {...app}>
        <ConfigProvider theme={theme}>
          <RouterProvider router={router} future={{ v7_startTransition: true }} />
        </ConfigProvider>
      </ContextProvider>
    </ApolloProvider>
  )
})

export { useApp }

export default App
