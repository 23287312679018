import "./Layout.less"

import { Layout } from "antd"
import { FC, Suspense, useEffect, useLayoutEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import { useApp } from "src/app"
import { useMeQuery, useUserLazyQuery } from "src/graphql"
import { useBreakpoints } from "src/shared/hooks"
import Header from "./Header"
import Sider from "./Sider"

export const DefaultLayout: FC = () => {
  const { user } = useApp()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isMobile } = useBreakpoints()

  const { data: meData } = useMeQuery()
  const [getUser, { data: userData }] = useUserLazyQuery()

  const userEmail = userData?.usersPermissionsUser?.data?.attributes?.email ?? null

  useEffect(() => {
    const id = meData?.me?.id

    if (id) {
      getUser({ variables: { id } })
    }

    user.setEmail(userEmail)
  }, [getUser, meData?.me?.id, user, userEmail])

  useLayoutEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  useLayoutEffect(() => {
    if (localStorage.getItem("jwt")) {
      user.authenticated = true
    } else {
      user.authenticated = false
    }

    if (!user.authenticated) {
      navigate("/login")
    }
  }, [navigate, user, user.authenticated])

  return (
    <Layout className={"layout"}>
      {!isMobile && (
        <Layout.Sider theme={"light"}>
          <Sider />
        </Layout.Sider>
      )}
      <Layout className={"header-content-layout"}>
        <Layout.Header className={"default-header"}>
          <Header />
        </Layout.Header>
        <Layout.Content>
          <Suspense>
            <Outlet />
          </Suspense>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}
