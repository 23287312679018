import { AutoComplete, Input, Typography } from "antd"
import { DefaultOptionType } from "antd/es/select"
import { debounce } from "lodash"
import { FC, useState } from "react"
import { getSearchDataType } from "src/shared/helpers"
import { HighlightText, SvgIcon } from "src/shared/ui"

export type AutocompleteDataType = {
  id: string
  type?: string
  title: string
}

type AutocompleteProps = {
  getData: (value: string) => Promise<AutocompleteDataType[]>
}

const searchResult = (value: string, data: AutocompleteDataType[]) =>
  data.map(it => ({
    value: it.id,
    label: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <HighlightText text={it.title} highlight={value} />
        {it.type && <Typography style={{ color: "grey" }}>{getSearchDataType(it.type)}</Typography>}
      </div>
    ),
  }))

export const Autocomplete: FC<AutocompleteProps> = ({ getData }) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([])

  const handleSearch = async (value: string) => {
    if (!value) {
      setOptions([])
      return
    }

    const data = await getData(value)

    setOptions(searchResult(value, data))
  }

  const onSelect = (value: string) => {
    console.log("onSelect", value)
  }

  return (
    <AutoComplete style={{ width: 300 }} options={options} onSelect={onSelect} onSearch={debounce(handleSearch, 500)}>
      <Input.Search
        className={"header-search"}
        prefix={<SvgIcon type={"searchInput"} />}
        placeholder={"Search"}
        size={"large"}
      />
    </AutoComplete>
  )
}
