import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataAccount",
      "ComponentDataEntry",
      "ComponentDataFile",
      "ComponentDataOrderItem",
      "ComponentDataPayment",
      "ComponentDataProductOrder",
      "ComponentDataProductPrivacy",
      "ComponentDataSerialsCode",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiFooterNavGroup",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Coupon",
      "Issue",
      "Landing",
      "Layout",
      "Message",
      "Notification",
      "Order",
      "Product",
      "ReactIconsIconlibrary",
      "ReportFile",
      "Shop",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "WaitingListEmail"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataAccount",
      "ComponentDataEntry",
      "ComponentDataFile",
      "ComponentDataOrderItem",
      "ComponentDataPayment",
      "ComponentDataProductOrder",
      "ComponentDataProductPrivacy",
      "ComponentDataSerialsCode",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiFooterNavGroup",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Coupon",
      "Issue",
      "Landing",
      "Layout",
      "Message",
      "Notification",
      "Order",
      "Product",
      "ReactIconsIconlibrary",
      "ReportFile",
      "Shop",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "WaitingListEmail"
    ]
  }
};
      export default result;
    
export const UploadFileFragmentDoc = gql`
    fragment UploadFile on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductEntity {
  id
  attributes {
    name
    description
    codes
    files {
      data {
        id
        attributes {
          name
          url
        }
      }
    }
    type
    revenue
    views
    image {
      data {
        ...UploadFile
      }
    }
    codesOptions {
      minQuantity
      maxQuantity
      warrantyDuration
      warrantyDetails
      isUnlimited
      customStockDelimiter
    }
    payment {
      frequencyOfPayment
      oneTimePrice
      subscriptionPrice
      methods
    }
    privacy {
      unlisted
      private
      hideStock
    }
    user {
      data {
        attributes {
          shop {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const CouponFragmentDoc = gql`
    fragment Coupon on Coupon {
  name
  code
  discount
  products {
    data {
      ...Product
    }
  }
  numberOfUses
  uses
  infinite
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const IssueFragmentDoc = gql`
    fragment Issue on Issue {
  topic
  email
  status
  messages {
    data {
      id
      attributes {
        createdAt
        user
        message
      }
    }
  }
}
    `;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  createdAt
  user
  message
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  type
  message
  isRead
  link
  createdAt
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  createdAt
  uid
  products {
    id
    quantity
    product {
      data {
        ...Product
      }
    }
  }
  price
  status
  email
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const ReportFileFragmentDoc = gql`
    fragment ReportFile on ReportFile {
  reportType
  fileType
  fileLink
  startDate
  endDate
  createdAt
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...UploadFile
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const ShopFragmentDoc = gql`
    fragment Shop on ShopEntity {
  id
  attributes {
    name
    url
    user {
      data {
        attributes {
          products {
            data {
              ...Product
            }
          }
        }
      }
    }
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUserEntity {
  id
  attributes {
    email
    username
  }
}
    `;
export const CreateCouponDocument = gql`
    mutation createCoupon($coupon: CouponInput!) {
  createCoupon(data: $coupon) {
    data {
      attributes {
        ...Coupon
      }
    }
  }
}
    ${CouponFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export const CreateIssueDocument = gql`
    mutation createIssue($data: IssueInput!) {
  createIssue(data: $data) {
    data {
      id
      attributes {
        email
        topic
        status
      }
    }
  }
}
    `;
export type CreateIssueMutationFn = Apollo.MutationFunction<CreateIssueMutation, CreateIssueMutationVariables>;
export function useCreateIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueMutation, CreateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssueMutation, CreateIssueMutationVariables>(CreateIssueDocument, options);
      }
export type CreateIssueMutationHookResult = ReturnType<typeof useCreateIssueMutation>;
export type CreateIssueMutationResult = Apollo.MutationResult<CreateIssueMutation>;
export const CreateMessageDocument = gql`
    mutation createMessage($data: MessageInput!) {
  createMessage(data: $data) {
    data {
      id
      attributes {
        user
        message
      }
    }
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export const CreateNotificationDocument = gql`
    mutation createNotification($data: NotificationInput!) {
  createNotification(data: $data) {
    data {
      id
      attributes {
        type
        message
      }
    }
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export const CreateOrderDocument = gql`
    mutation createOrder($data: CreateOrderInput!) {
  createOrder(data: $data) {
    id
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export const CreateProductDocument = gql`
    mutation createProduct($product: ProductInput!) {
  createProduct(data: $product) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export const CreateReportFileDocument = gql`
    mutation createReportFile($report: ReportFileInput!) {
  createReportFile(data: $report) {
    data {
      id
      attributes {
        reportType
        fileType
        startDate
        endDate
      }
    }
  }
}
    `;
export type CreateReportFileMutationFn = Apollo.MutationFunction<CreateReportFileMutation, CreateReportFileMutationVariables>;
export function useCreateReportFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportFileMutation, CreateReportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportFileMutation, CreateReportFileMutationVariables>(CreateReportFileDocument, options);
      }
export type CreateReportFileMutationHookResult = ReturnType<typeof useCreateReportFileMutation>;
export type CreateReportFileMutationResult = Apollo.MutationResult<CreateReportFileMutation>;
export const CreateWaitingListEmailDocument = gql`
    mutation createWaitingListEmail($data: WaitingListEmailInput!) {
  createWaitingListEmail(data: $data) {
    data {
      attributes {
        email
      }
    }
  }
}
    `;
export type CreateWaitingListEmailMutationFn = Apollo.MutationFunction<CreateWaitingListEmailMutation, CreateWaitingListEmailMutationVariables>;
export function useCreateWaitingListEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitingListEmailMutation, CreateWaitingListEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitingListEmailMutation, CreateWaitingListEmailMutationVariables>(CreateWaitingListEmailDocument, options);
      }
export type CreateWaitingListEmailMutationHookResult = ReturnType<typeof useCreateWaitingListEmailMutation>;
export type CreateWaitingListEmailMutationResult = Apollo.MutationResult<CreateWaitingListEmailMutation>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($id: ID!) {
  deleteCoupon(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export const DeleteIssueDocument = gql`
    mutation deleteIssue($id: ID!) {
  deleteIssue(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteIssueMutationFn = Apollo.MutationFunction<DeleteIssueMutation, DeleteIssueMutationVariables>;
export function useDeleteIssueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIssueMutation, DeleteIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIssueMutation, DeleteIssueMutationVariables>(DeleteIssueDocument, options);
      }
export type DeleteIssueMutationHookResult = ReturnType<typeof useDeleteIssueMutation>;
export type DeleteIssueMutationResult = Apollo.MutationResult<DeleteIssueMutation>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export const DeleteReportFileDocument = gql`
    mutation deleteReportFile($id: ID!) {
  deleteReportFile(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteReportFileMutationFn = Apollo.MutationFunction<DeleteReportFileMutation, DeleteReportFileMutationVariables>;
export function useDeleteReportFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportFileMutation, DeleteReportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportFileMutation, DeleteReportFileMutationVariables>(DeleteReportFileDocument, options);
      }
export type DeleteReportFileMutationHookResult = ReturnType<typeof useDeleteReportFileMutation>;
export type DeleteReportFileMutationResult = Apollo.MutationResult<DeleteReportFileMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      email
      username
      confirmed
      role {
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($id: ID!, $coupon: CouponInput!) {
  updateCoupon(id: $id, data: $coupon) {
    data {
      attributes {
        ...Coupon
      }
    }
  }
}
    ${CouponFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export const UpdateIssueDocument = gql`
    mutation updateIssue($id: ID!, $issue: IssueInput!) {
  updateIssue(id: $id, data: $issue) {
    data {
      id
    }
  }
}
    `;
export type UpdateIssueMutationFn = Apollo.MutationFunction<UpdateIssueMutation, UpdateIssueMutationVariables>;
export function useUpdateIssueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssueMutation, UpdateIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIssueMutation, UpdateIssueMutationVariables>(UpdateIssueDocument, options);
      }
export type UpdateIssueMutationHookResult = ReturnType<typeof useUpdateIssueMutation>;
export type UpdateIssueMutationResult = Apollo.MutationResult<UpdateIssueMutation>;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($id: ID!, $data: NotificationInput!) {
  updateNotification(id: $id, data: $data) {
    data {
      id
      attributes {
        type
        message
      }
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: ID!, $order: OrderInput!) {
  updateOrder(id: $id, data: $order) {
    data {
      attributes {
        ...Order
      }
    }
  }
}
    ${OrderFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: ID!, $data: ProductInput!) {
  updateProduct(id: $id, data: $data) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export const UpdateReportFileDocument = gql`
    mutation updateReportFile($id: ID!, $input: ReportFileInput!) {
  updateReportFile(id: $id, data: $input) {
    data {
      id
      attributes {
        reportType
        fileType
        startDate
        endDate
      }
    }
  }
}
    `;
export type UpdateReportFileMutationFn = Apollo.MutationFunction<UpdateReportFileMutation, UpdateReportFileMutationVariables>;
export function useUpdateReportFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportFileMutation, UpdateReportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportFileMutation, UpdateReportFileMutationVariables>(UpdateReportFileDocument, options);
      }
export type UpdateReportFileMutationHookResult = ReturnType<typeof useUpdateReportFileMutation>;
export type UpdateReportFileMutationResult = Apollo.MutationResult<UpdateReportFileMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
      attributes {
        username
        email
        avatar {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!) {
  upload(file: $file) {
    data {
      id
    }
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export const CouponDocument = gql`
    query coupon($id: ID!) {
  coupon(id: $id) {
    data {
      id
      attributes {
        ...Coupon
      }
    }
  }
}
    ${CouponFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const CouponsDocument = gql`
    query coupons($filters: CouponFiltersInput) {
  coupons(pagination: {limit: 100}, filters: $filters) {
    data {
      id
      attributes {
        ...Coupon
      }
    }
  }
}
    ${CouponFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useCouponsQuery(baseOptions?: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const DashboardAnalyticsDocument = gql`
    query dashboardAnalytics($data: DashboardAnalyticsInput) {
  dashboardAnalytics(data: $data) {
    revenue
    orders
    views
    conversion
  }
}
    `;
export function useDashboardAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
      }
export function useDashboardAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
        }
export type DashboardAnalyticsQueryHookResult = ReturnType<typeof useDashboardAnalyticsQuery>;
export type DashboardAnalyticsLazyQueryHookResult = ReturnType<typeof useDashboardAnalyticsLazyQuery>;
export type DashboardAnalyticsQueryResult = Apollo.QueryResult<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>;
export const FileDocument = gql`
    query file($id: ID!) {
  uploadFile(id: $id) {
    data {
      attributes {
        url
      }
    }
  }
}
    `;
export function useFileQuery(baseOptions: Apollo.QueryHookOptions<FileQuery, FileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileQuery, FileQueryVariables>(FileDocument, options);
      }
export function useFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileQuery, FileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileQuery, FileQueryVariables>(FileDocument, options);
        }
export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileQueryResult = Apollo.QueryResult<FileQuery, FileQueryVariables>;
export const IssueDocument = gql`
    query issue($id: ID!) {
  issue(id: $id) {
    data {
      id
      attributes {
        ...Issue
      }
    }
  }
}
    ${IssueFragmentDoc}`;
export function useIssueQuery(baseOptions: Apollo.QueryHookOptions<IssueQuery, IssueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssueQuery, IssueQueryVariables>(IssueDocument, options);
      }
export function useIssueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssueQuery, IssueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssueQuery, IssueQueryVariables>(IssueDocument, options);
        }
export type IssueQueryHookResult = ReturnType<typeof useIssueQuery>;
export type IssueLazyQueryHookResult = ReturnType<typeof useIssueLazyQuery>;
export type IssueQueryResult = Apollo.QueryResult<IssueQuery, IssueQueryVariables>;
export const IssuesDocument = gql`
    query issues($filter: IssueFiltersInput) {
  issues(pagination: {limit: 100}, filters: $filter) {
    data {
      id
      attributes {
        ...Issue
      }
    }
  }
}
    ${IssueFragmentDoc}`;
export function useIssuesQuery(baseOptions?: Apollo.QueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, options);
      }
export function useIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IssuesQuery, IssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IssuesQuery, IssuesQueryVariables>(IssuesDocument, options);
        }
export type IssuesQueryHookResult = ReturnType<typeof useIssuesQuery>;
export type IssuesLazyQueryHookResult = ReturnType<typeof useIssuesLazyQuery>;
export type IssuesQueryResult = Apollo.QueryResult<IssuesQuery, IssuesQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
        footerSocialMedia {
          ...Link
        }
        footerNav {
          title
          links {
            ...Link
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    email
    username
    avatar {
      data {
        attributes {
          url
          alternativeText
        }
      }
    }
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NotificationsDocument = gql`
    query notifications {
  notifications(pagination: {limit: 100}) {
    data {
      id
      attributes {
        ...Notification
      }
    }
  }
}
    ${NotificationFragmentDoc}`;
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const OrdersDocument = gql`
    query orders($filters: OrderFiltersInput) {
  orders(pagination: {limit: 100}, filters: $filters) {
    data {
      id
      attributes {
        ...Order
      }
    }
  }
}
    ${OrderFragmentDoc}
${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const PaymentVerificationDocument = gql`
    query paymentVerification($id: ID!) {
  paymentVerification(id: $id) {
    status
    wallet
    shopName
    usdPrice
    email
    slug
    createdAt
    btcPrice
    products {
      name
      codes
      files
    }
  }
}
    `;
export function usePaymentVerificationQuery(baseOptions: Apollo.QueryHookOptions<PaymentVerificationQuery, PaymentVerificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentVerificationQuery, PaymentVerificationQueryVariables>(PaymentVerificationDocument, options);
      }
export function usePaymentVerificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentVerificationQuery, PaymentVerificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentVerificationQuery, PaymentVerificationQueryVariables>(PaymentVerificationDocument, options);
        }
export type PaymentVerificationQueryHookResult = ReturnType<typeof usePaymentVerificationQuery>;
export type PaymentVerificationLazyQueryHookResult = ReturnType<typeof usePaymentVerificationLazyQuery>;
export type PaymentVerificationQueryResult = Apollo.QueryResult<PaymentVerificationQuery, PaymentVerificationQueryVariables>;
export const ProductDocument = gql`
    query product($id: ID!) {
  product(id: $id) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
    query products($filters: ProductFiltersInput, $pagination: PaginationArg) {
  products(filters: $filters, pagination: $pagination) {
    data {
      ...Product
    }
    meta {
      pagination {
        total
        pageCount
        page
      }
    }
  }
}
    ${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ReportFilesDocument = gql`
    query reportFiles($filter: ReportFileFiltersInput) {
  reportFiles(pagination: {limit: 100}, filters: $filter) {
    data {
      id
      attributes {
        ...ReportFile
      }
    }
  }
}
    ${ReportFileFragmentDoc}`;
export function useReportFilesQuery(baseOptions?: Apollo.QueryHookOptions<ReportFilesQuery, ReportFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportFilesQuery, ReportFilesQueryVariables>(ReportFilesDocument, options);
      }
export function useReportFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportFilesQuery, ReportFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportFilesQuery, ReportFilesQueryVariables>(ReportFilesDocument, options);
        }
export type ReportFilesQueryHookResult = ReturnType<typeof useReportFilesQuery>;
export type ReportFilesLazyQueryHookResult = ReturnType<typeof useReportFilesLazyQuery>;
export type ReportFilesQueryResult = Apollo.QueryResult<ReportFilesQuery, ReportFilesQueryVariables>;
export const SearchDocument = gql`
    query search($q: String!) {
  search(q: $q) {
    id
    type
    title
  }
}
    `;
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ShopDocument = gql`
    query shop($url: String!, $product: ID!) {
  shops(filters: {url: {eq: $url}}) {
    data {
      id
      attributes {
        name
        user {
          data {
            attributes {
              products(filters: {id: {eq: $product}}) {
                data {
                  ...Product
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ProductFragmentDoc}
${UploadFileFragmentDoc}`;
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        email
        avatar {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;